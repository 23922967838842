import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import Section from "../components/section"
import Container from "../components/container"
import Box from "../components/box"

const NotFoundPage = ({ _path, data }) => {
  return (
    <Layout path={"/404"}>
      <SEO title="404" />
      <Section id={"NotFoundSection"}>
        <Container appear={"fromTop"}>
          <Box alignText={"center"}>
            <h1>404</h1>
          </Box>
        </Container>
      </Section>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
    query NotFoundQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`